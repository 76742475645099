import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), },
  { path: 'artist', loadChildren: () => import('./artist/artist.module').then(m => m.ArtistModule), canActivate: [AuthGuard] },
  { path: 'artwork', loadChildren: () => import('./artwork/artwork.module').then(m => m.ArtworkModule), canActivate: [AuthGuard] },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule), canActivate: [AuthGuard] },
  { path: 'content', loadChildren: () => import('./content/content.module').then(m => m.ContentModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule { }