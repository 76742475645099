import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  public userName: any;

  constructor(
    private apiService: ApiService) { }

  ngOnInit() {
    this.initCall();
  }

  async initCall() {
    let userInfo = await this.apiService.storageService.getItem('userName')
    if (userInfo) {
      this.userName = userInfo;
    }
  }

  logOut() {
    this.apiService.storageService.clear().finally(() => {
      localStorage.clear();
      this.apiService.router.navigate([''])
    });
  }

}
