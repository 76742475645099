import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public userdetails: any;
  public token: any;
  public userRoleidget: any;
  public httpOptions: any;
  public showHead: boolean = false;

  //Live Api Domains.
  public domain = 'https://api.artbyrs.com/dashboard/v1/';
  public url = 'https://api.artbyrs.com/';

  //Test Api Domains.
  // public domain = 'https://test-api.artbyrs.com/dashboard/v1/';
  // public url = 'https://test-api.artbyrs.com/';

  //Test Api Domains.
  // public domain = 'http://localhost:5059/dashboard/v1/';
  // public url = 'http://localhost:5059/';

  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    public router: Router,
    public toastr: ToastrService,
    public modalService: NgbModal) { }

  async getHeader(type: any) {
    return this.storageService.getItem('token').then((res: any) => {
      if (res) {
        if (type == 'all') {
          return this.httpOptions = {
            headers: new HttpHeaders({
              "Authorization": 'bearer ' + res.toString(),
            })
          };
        } else if (type == 'token') {
          return this.httpOptions = {
            headers: new HttpHeaders({
              "Authorization": 'bearer ' + res.toString(),
            })
          };
        }
      }
    }).catch(err => { });
  }

  async getRequest(api: any, data: any, type = 'all') {
    return new Promise((resolve, reject) => {
      this.getHeader(type).then((dbres: any) => {
        if (dbres) {
          this.http.get<any>(this.domain + api + data, this.httpOptions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.get<any>(this.domain + api + data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    }).catch(err => { });
  }

  postRequest(api: any, data: any, type = 'all') {
    return new Promise((resolve, reject) => {
      this.getHeader(type).then((dbres: any) => {
        if (dbres) {
          this.http.post<any>(this.domain + api, data, this.httpOptions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.post<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  putRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.getHeader('all').then((dbres: any) => {
        if (dbres) {
          this.http.put<any>(this.domain + api, data, this.httpOptions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.put<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  patchRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.getHeader('token').then((dbres: any) => {
        if (dbres) {
          this.http.patch<any>(this.domain + api, data, this.httpOptions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.patch<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  deleteRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.getHeader('token').then((dbres: any) => {
        if (dbres) {
          this.httpOptions['body'] = data;
          this.http.delete<any>(this.domain + api, this.httpOptions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
        else {
          this.http.delete<any>(this.domain + api + data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

}
